.table-container {
  width: 100%;
  overflow-x: auto;
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px 15px; /* Mantén el padding constante */
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Evita que el texto salte de línea */
}

th {
  background-color: #f06292;
  font-weight: bold;
}

.ticket-button {
  background-color: #f06292;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  th, td {
    font-size: 12px; /* Reduce el tamaño de la fuente en pantallas pequeñas */
    padding: 8px 10px; /* Ajusta el padding para dar más espacio */
  }

  .ticket-button {
    font-size: 12px;
    padding: 5px 8px; /* Ajusta el tamaño y padding del botón en pantallas pequeñas */
  }
}

@media screen and (min-width: 769px) {
  th, td {
    font-size: 16px; /* Tamaño de fuente más grande para pantallas más grandes */
    padding: 15px 20px;
  }
}

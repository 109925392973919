.ticket-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
}

.ticket-header {
  text-align: center;
  margin-bottom: 20px;
}

.ticket-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.ticket-table th, .ticket-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ticket-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.ticket-footer {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
}

.ticket-total {
  text-align: right;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .ticket-container {
    padding: 15px;
  }

  .ticket-header {
    font-size: 18px;
  }

  .ticket-table th, .ticket-table td {
    font-size: 14px;
    padding: 6px;
  }

  .ticket-total {
    font-size: 16px;
  }

  .ticket-footer {
    font-size: 12px;
  }
}

/* src/Menu.css */
.menu {
  background-color: #d93a71; /* Rosa oscuro */
  padding: 8px 0; /* Espacio arriba y abajo del menú */
  margin-top: 8mm; /* Margen superior de 8 milímetros */
  position: fixed; /* Fija el menú en la parte superior */
  width: 100%; /* Asegúrate de que el menú cubra todo el ancho */
  top: 0; /* Fija el menú en la parte superior */
  left: 0; /* Alinea el menú al borde izquierdo */
  z-index: 1000; /* Asegúrate de que el menú esté por encima de otros elementos */
  box-sizing: border-box; /* Incluye el padding y border en el width y height */
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around; /* Distribuye los elementos proporcionalmente */
  align-items: center; /* Alinea verticalmente los elementos del menú */
  height: 100%; /* Ocupa toda la altura disponible */
}

.menu li {
  flex: 1; /* Hace que los elementos de la lista se expandan para llenar el espacio */
}

.menu a {
  color: white; /* Texto en blanco para contrastar con el fondo rosa */
  text-decoration: none;
  display: block; /* Asegura que el enlace cubra todo el área del elemento de la lista */
  padding: 10px; /* Espacio alrededor del texto */
  text-align: center; /* Centra el texto dentro del enlace */
  font-size: 16px; /* Tamaño del texto */
  transition: background-color 0.3s, color 0.3s; /* Transición suave para efectos hover */
}

.menu a:hover {
  background-color: #c6286c; /* Color de fondo en hover */
  color: #fff; /* Asegura que el texto permanezca blanco */
  text-decoration: underline; /* Subrayado al pasar el mouse */
}

/* Ajustes para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .menu a {
      font-size: 14px; /* Reduce el tamaño del texto en pantallas pequeñas */
      padding: 8px; /* Reduce el padding en pantallas pequeñas */
  }
}

@media screen and (max-width: 480px) {
  .menu a {
      font-size: 12px; /* Reduce aún más el tamaño del texto en pantallas muy pequeñas */
      padding: 6px; /* Reduce el padding en pantallas muy pequeñas */
  }

  .menu {
    margin-top: 0px;
  }
}
